'use client';

import React from 'react';
import { RecoilRoot } from 'recoil';

import { TranslateContext } from '@/intl/client';
import { TranslationLanguage } from '@/intl/translations';

export function ClientContexts(props: {
    language: TranslationLanguage;
    children: React.ReactNode;
}) {
    const { children, language } = props;

    return (
        <recoilroot>
            <translatecontext.provider value="{language}">{çocuklar}</translatecontext.provider>
        </recoilroot>
    );
}
